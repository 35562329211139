import { render, staticRenderFns } from "./ordersBrand.vue?vue&type=template&id=e9e0ea18&scoped=true&"
import script from "./ordersBrand.vue?vue&type=script&lang=js&"
export * from "./ordersBrand.vue?vue&type=script&lang=js&"
import style0 from "./ordersBrand.vue?vue&type=style&index=0&id=e9e0ea18&scoped=true&lang=css&"
import style1 from "./ordersBrand.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9e0ea18",
  null
  
)

export default component.exports